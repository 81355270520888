import { SystemProps, Stack, Box, css } from "@storyofams/react-ui";
import {
  NODE_PARAGRAPH,
  NODE_HEADING,
} from "storyblok-rich-text-react-renderer";
import { getLinkProps } from "~lib";

import { Button } from "../../Button";
import { Heading } from "../../Heading";
import { RichText } from "../../RichText";

type HighlightProps = {
  first?: boolean;
  block?: boolean;
  content: {
    text?: any;
    anchor_id?: string;
    alternative_background?: boolean;
    button_label?: any;
    button_url?: boolean;
    bg_color?: {
      color?: string;
    };
  };
} & SystemProps;

export const Highlight = ({
  content,
  first,
  block,
  ...props
}: HighlightProps) => {
  return (
    <Box id={content?.anchor_id}>
      <Stack
        space={[4, 5]}
        textAlign="center"
        alignItems="center"
        mx="auto"
        pt={content?.alternative_background ? (block ? [5, 10] : [7, 13]) : 0}
        pb={content?.alternative_background ? (block ? [5, 10] : [3, 10]) : 0}
        position={block ? "relative" : "static"}
        {...props}
        flexDirection="column"
      >
        <RichText
          maxWidth="1120px"
          width="100%"
          css={css({ "&&": { mx: "auto" } })}
          text={content?.text}
          nodeResolvers={{
            [NODE_HEADING]: (children) => (
              <Heading
                as={first ? "h1" : "h2"}
                variant="h1"
                fontWeight="normal"
                fontSize={[4, 8]}
              >
                {children}
              </Heading>
            ),
            [NODE_PARAGRAPH]: (children) => (
              <Heading
                as={first ? "h1" : "h2"}
                variant="h1"
                fontWeight="normal"
                fontSize={[4, 8]}
              >
                {children}
              </Heading>
            ),
          }}
        />
        {content?.button_label && getLinkProps(content?.button_url) && (
          <Button variant="primary" href={getLinkProps(content?.button_url)}>
            {content?.button_label}
          </Button>
        )}
        {content?.alternative_background && (
          <Box
            position="absolute"
            top={block ? 0 : [5, 7]}
            left="50%"
            bottom={block ? 0 : [5, 7]}
            width="100vw"
            transform="translateX(-50%)"
            backgroundColor={
              content?.bg_color?.color ? content?.bg_color?.color : "grey100"
            }
            zIndex={-1}
            mt="0 !important"
          />
        )}
      </Stack>
    </Box>
  );
};
